import React from 'react';

function Career() {
  return (
    <div className="page">
      <h1>Career History</h1>
      <p>A graphical representation of my career path over time will be displayed here.</p>
    </div>
  );
}

export default Career;
