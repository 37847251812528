import React from 'react';

function Home() {
  return (
    <div className="page">
      <h1>Welcome to Jay-Freeman.com</h1>
      <p>This is the future home of my portfolio. </p>
    </div>
  );
}

export default Home;
