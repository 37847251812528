import React from 'react';

function Projects() {
  return (
    <div className="page">
      <h1>Projects</h1>
      <p>Details about my codings projects will be shared here.</p>
    </div>
  );
}

export default Projects;