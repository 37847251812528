import React from 'react';

function Contact() {
  return (
    <div className="page">
      <h1>Contact Me</h1>
      <p>You can reach me via email at [Your Email Here].</p>
    </div>
  );
}

export default Contact;
